<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';
import {
  ActiveColor,
  ForbidColor,
  funAMap,
  TYPE_CHART,
  TYPE_STATE,
} from '../util';
import { createFormModal } from '@triascloud/x-components';
import BuildSetting from './building-setting';
import BeaconSetting from './beacon-setting';
import * as turf from '@turf/turf';
import { crossStorageModule } from '@/enum/store';
import { fabric } from './graphic-tools';
import {
  saveOrUpdate,
  page as getBeacons,
} from '@/services/smart-hat/geofenceFloorBeacon';
import {
  addBuilding,
  putBuilding,
  getInfo,
} from '@/services/smart-hat/geofenceZoneFlat';
import {
  addLayer,
  updateLayer,
  getInfo as getLayerInfo,
} from '@/services/smart-hat/geofenceFlatFloor';
import { uuid } from '@triascloud/utils';

const whileAlong = list => {
  const maxStep = 50;
  let i = maxStep,
    result = [];
  for (;;) {
    const lineX = turf.lineString(list);
    const along = turf.along(lineX, i / 1000);
    if (
      result[result.length - 1] &&
      result[result.length - 1].point === along.geometry.coordinates
    ) {
      break;
    }
    result.push({
      point: along.geometry.coordinates,
      count: i,
    });
    i += maxStep;
  }
  result.pop();
  return result;
};

const customService = new UploadService('/oss/oss');
@Component()
export default class fn3D extends Vue {
  @Prop({ type: Object }) detail;
  @Prop({ type: Object }) center;
  @crossStorageModule.State('tenant') tenant;

  async mounted() {
    await this.fetchBuildInfo();
    await this.initMap();
  }

  map = null;
  AMap = undefined;
  defaultLayerShow = true;
  defaultLayer = undefined;
  async initMap() {
    const AMap = await funAMap({
      plugins: ['AMap.Scale'],
    });
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    const defaultLayer = AMap.createDefaultLayer();
    this.defaultLayer = defaultLayer;
    const mapOption = {
      zoom: 17,
      doubleClickZoom: false,
      center: [],
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
      layers: [defaultLayer],
    };
    if (this.center && this.center.lng) {
      mapOption['center'] = [this.center.lng, this.center.lat];
    }
    this.map = new AMap.Map('fn3dBox', mapOption);

    this.map.on('zoomchange', () => {
      this.adjustTextByZoom();
    });

    // 添加比例尺
    const scale = new AMap.Scale();
    this.map.addControl(scale);

    this.initCanvas();
    this.initFence(this.detail);
  }

  newChartShow = true;
  newChart = undefined;
  /** @name 围栏包围盒左上点坐标 */
  LT_LngLat = undefined;
  initFence(chart) {
    const commonColor = '#999999';
    const colorFn = (state, key) =>
      state === TYPE_STATE.active ? ActiveColor[key] : ForbidColor[key];
    const opts = {
      zIndex: chart.zIndex,
      strokeColor: colorFn(chart.state, 'stroke'),
      fillColor: colorFn(chart.state, 'fill'),
      strokeWeight: 3,
      extData: {
        _state_: chart.state,
        _name_: chart.name,
        _desc_: chart.desc,
        _id_: chart.id,
        _uid_: chart.id ? chart.id : chart.uid,
        _geofenceId_: chart.geofenceId ? chart.geofenceId : '',
      },
    };
    let newChart = undefined;
    let shape = undefined;
    if (chart.type === TYPE_CHART.polygon) {
      newChart = new this.AMap.Polygon({
        path: chart.path,
        ...opts,
      });
      shape = turf.polygon([[...chart.path, chart.path[0]]]);
    } else {
      newChart = new this.AMap.Circle({
        center: chart.center,
        radius: chart.radius,
        ...opts,
      });
      shape = turf.circle(chart.center, chart.radius / 1000);
    }
    // 左下，右下，右上，左上，左下
    const rectangle = turf.bboxPolygon(turf.bbox(shape)).geometry
      .coordinates[0];
    const box = {
      lb: 0,
      lt: 0,
      rb: 0,
      rt: 0,
    };
    if (rectangle.length > 0) {
      box.lb = rectangle[0];
      box.rb = rectangle[1];
      box.rt = rectangle[2];
      box.lt = rectangle[3];
      this.LT_LngLat = this.map.lngLatToContainer(box.lt);
    }
    chart.target = newChart;
    newChart.extData = {
      ...opts.extData,
      _box_: box,
    };
    this.newChart = newChart;
    this.map.add(newChart);

    // 绘制刻度坐标轴
    box.rt = [box.rt[0] + 0.0016, box.rt[1]];
    box.lb = [box.lb[0], box.lb[1] - 0.0016];
    const polylineDemo = new this.AMap.Polyline({
      path: [box.rt, box.lt, box.lb],
      zooms: [17, 20],
      strokeOpacity: 1,
      strokeColor: commonColor,
      strokeWeight: 1,
      lineCap: 'square',
    });
    this.map.add(polylineDemo);
    // this.map.setFitView([newChart]);
    // 绘制刻度点前，先计算距离
    const lineL = whileAlong([box.lt, box.lb]);
    const pointLList = lineL.map(L => {
      return new this.AMap.Polyline({
        path: [L.point, [L.point[0] + 0.00003, [L.point[1]]]],
        strokeColor: commonColor,
        strokeWeight: 2,
        zooms: [17, 20],
        // strokeStyle: 'dashed',
        // strokeDasharray: [5, 5],
        lineCap: 'square',
      });
    });
    const textLList = lineL.map(L => {
      return new this.AMap.Text({
        position: [[L.point[0] - 0.00006], L.point[1]],
        text: L.count,
        anchor: 'center',
        zooms: [17, 20],
        style: {
          'color': commonColor,
          'background-color': 'transparent',
          'border': 'none',
        },
      });
    });
    const lineX = whileAlong([box.lt, box.rt]);
    const pointXList = lineX.map(L => {
      return new this.AMap.Polyline({
        path: [L.point, [L.point[0], [L.point[1] - 0.00003]]],
        strokeColor: commonColor,
        strokeWeight: 2,
        zooms: [17, 20],
        // strokeStyle: 'dashed',
        // strokeDasharray: [5, 5],
        lineCap: 'square',
      });
    });
    const textXList = lineX.map(L => {
      return new this.AMap.Text({
        position: [[L.point[0]], L.point[1] + 0.00006],
        text: L.count,
        anchor: 'center',
        zooms: [17, 20],
        style: {
          'color': commonColor,
          'background-color': 'transparent',
          'border': 'none',
        },
      });
    });
    this.textList = [...textLList, ...textXList];
    this.map.add([...textLList, ...pointLList, ...textXList, ...pointXList]);
  }
  textList = [];
  adjustTextByZoom() {
    const calculateFontSizeByZoom = zoom => {
      if (zoom < 18) {
        return 8;
      }
      if (zoom < 19) {
        return 10;
      }
      if (zoom < 20) {
        return 12;
      }
      return 6;
    };
    let zoom = this.map.getZoom();
    this.textList.forEach(text => {
      text.setStyle({
        'font-size': calculateFontSizeByZoom(zoom) + 'px',
      });
    });
  }
  canvasAim;
  initCanvas() {
    const targetCanvas = document.createElement('canvas');
    const size = this.map.getSize();
    targetCanvas.style.width = size.width + 'px';
    targetCanvas.style.height = size.height + 'px';
    targetCanvas.width = size.width;
    targetCanvas.height = size.height;
    this.canvasAim = new fabric.Canvas(targetCanvas, {
      offsetEl: document.getElementById('fn3dBox'),
      map: this.map,
      amap: this.AMap,
      setDragStatusFalse: () => {
        this.map.setStatus({ dragEnable: false });
      },
      setDragStatusTrue: () => {
        this.map.setStatus({ dragEnable: true });
      },
    });
    this.map.setStatus({
      doubleClickZoom: false,
      rotateEnable: false,
    });

    const customLayer = new this.AMap.CustomLayer(targetCanvas, {
      align: 'center',
      // zIndex: 999999,
    });
    customLayer.render = this.canvasAim.renderAll.bind(
      this.canvasAim,
      customLayer,
    );
    this.customLayer = customLayer;
    customLayer.setMap(this.map);
  }
  customLayer;
  async uploadFile(file) {
    const uploadOptions = this.uploadOptions;
    const files = file.file;
    const pkId = this.tenant.pkId;
    const uploadPath = `${pkId}/connector/geofence/file`;
    const res = await customService.upload(
      files,
      uploadPath,
      e => {
        e.addEventListener('responseprogress', p => {
          uploadOptions.onProgress(
            { percent: +Math.round((p.loaded / p.total) * 100).toFixed(2) },
            files,
          );
        });
      },
      false,
    );
    uploadOptions.onSuccess(res, files);
    return res;
  }
  async customUpload(options) {
    const maxSize = 10;
    if (options.file.size > maxSize * 1024 * 1024) {
      this.$message.warn(`文件${options.file.name}大小超过${maxSize}M！`);
      return;
    }
    this.uploadOptions = options;
    // 图片上传OSS
    let ossPath = await this.uploadFile(options);
    const abPath = await customService.getAuth(ossPath);

    const reader = new FileReader();
    reader.onload = i => {
      const imageSrc = i.target.result;
      this.loadImageMarker(
        imageSrc,
        {
          uid: options.file.uid,
          name: options.file.name,
          file: options.file,
        },
        {
          ossPath,
          abPath,
        },
      );
    };
    reader.readAsDataURL(options.file);
  }
  imageArray = [];
  /** @name 图层图片操作对象 */
  temporaryImage;
  loadImageMarker(imageSrc, options, path, imageOption = {}) {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      // let width, height;
      // // width = Number((image.width / 100).toFixed(2));
      // // height = Number((image.height / 100).toFixed(2));
      // width = image.width;
      // height = image.height;
      const distance = this.map.getScale(window.devicePixelRatio);
      console.log(`距离：${distance}`);
      // 43.25/4325 1/100
      // 计算当前电子围栏包围盒的左上角的点的像素坐标
      this.LT_LngLat = this.map.lngLatToContainer(
        this.newChart.extData._box_.lt,
      );
      this.customLayer.show();
      if (imageOption && imageOption.edited) {
        const temporary = this.temporaryList.find(
          v => v.uid == imageOption.uid,
        );
        this.canvasAim.setActiveObject(temporary);
        this.ImageLoadCallback(temporary, options, path);
      } else {
        fabric.FabricImage.fromURL(
          imageSrc,
          img => {
            this.ImageLoadCallback(img, options, path);
          },
          {
            width: image.width,
            height: image.height,
            left: this.LT_LngLat.x + image.width / 2,
            top: this.LT_LngLat.y + image.height / 2,
            canvasLocal: this.canvasAim,
            map: this.map,
            boxLT: this.newChart.extData._box_.lt,
            ...imageOption,
          },
        );
      }
    };
  }
  /** @name 图片图层列表 */
  temporaryList = [];
  ImageLoadCallback(img, options, path) {
    const fItem = this.temporaryList.find(i => i.uid === img.uid);
    if (!fItem) {
      this.temporaryList.push(img);
    }
    this.canvasAim.add(img);
    const imageOption = {
      uid: options.file.uid,
      name: options.file.name,
      status: 'done',
      url: path.abPath,
      ossPath: path.ossPath,
      file: options.file,
      img,
    };
    this.fileList.push(imageOption);
    this.imageArray.push(imageOption);
    this.layerForm.floorPlanImage = path.ossPath;
    this.canvasAim.on('object:modified', t => {
      this.layerForm.targetImageLayer = t.target.imageLayer;
      this.layerForm.imgWidth = Number(
        (t.target.width * t.target.scaleX).toFixed(2),
      );
      this.layerForm.imgHeight = Number(
        (t.target.height * t.target.scaleY).toFixed(2),
      );
      this.layerForm.planeAngle = t.target.angle;
      this.layerForm.planeX = t.target.plane.x;
      this.layerForm.planeY = t.target.plane.y;
      this.layerForm.planeLng = t.target.plane.lng;
      this.layerForm.planeLat = t.target.plane.lat;
      this.layerForm.planeBaseX = t.target.planeBase.x;
      this.layerForm.planeBaseY = t.target.planeBase.y;
      this.layerForm.planeBaseLng = t.target.planeBase.lng;
      this.layerForm.planeBaseLat = t.target.planeBase.lat;
      console.log(this.layerForm, t.target.plane, t.target.planeBase);
    });
  }
  handleRemove() {}
  layerForm = {
    pkId: '',
    floorName: '',
    floorPlanImage: '',
    floorIdentifier: '',
    planeX: 0,
    planeY: 0,
    planeAngle: 0,
    planeLng: 0,
    planeLat: 0,
    planeBaseX: 0,
    planeBaseY: 0,
    planeBaseDirect: 0,
    planeBaseLng: 0,
    planeBaseLat: 0,
    imgWidth: 0,
    imgHeight: 0,
    imgOpacity: 1,
    floorHeight: '',
    target: null,
    targetImageLayer: null,
  };
  layerRules = {};
  fileList = [];
  buildDetail = {};
  async fetchBuildInfo() {
    try {
      console.log(this.detail);
      const result = await getInfo(this.detail.uid);
      this.buildDetail = result || {};
      if (result.backgroundDisplay) {
        this.defaultLayerShow = true;
      } else {
        this.defaultLayerShow = false;
      }
      if (result.zoneNameDisplay) {
        this.newChartShow = true;
      } else {
        this.newChartShow = false;
      }
      if (result.floorBaseVOList && result.floorBaseVOList.length > 0) {
        const currentLayer = result.floorBaseVOList.slice(-1)[0];
        this.formLayers.push({
          ...currentLayer,
          targetShow: true,
          beacon: {},
        });
      }
    } catch (e) {
      window.console.error(e);
    }
  }
  async handleBuildSetting() {
    const result = await createFormModal(
      () => <BuildSetting detail={this.buildDetail} />,
      {
        width: 500,
        title: '楼栋信息',
        maskClosable: false,
      },
    );
    let params = {
      backgroundDisplay: this.defaultLayerShow,
      buildName: result.name,
      idxGeofenceZoneId: this.detail.uid,
      zoneNameDisplay: this.newChartShow,
    };
    if (result.buildId) {
      params.buildId = result.buildId;
    }
    let interfaceApi = addBuilding;
    if (this.buildDetail && this.buildDetail.pkId) {
      interfaceApi = putBuilding;
      params.buildId = this.buildDetail.pkId;
      params.pkId = this.buildDetail.pkId;
    }
    await interfaceApi(params);
    await this.fetchBuildInfo();
  }
  beaconMarkerList = [];
  currentLayer = null;
  async handleBeaconSetting(item) {
    const layerResult = await getLayerInfo(item.pkId);
    const plane = layerResult.planInfo;
    const planeBase = layerResult.planMarkInfo;
    item.planeX = plane.planeX;
    item.planeY = plane.planeY;
    item.planeAngle = plane.planeAngle;
    item.planeLng = plane.planeLng;
    item.planeLat = plane.planeLat;
    item.planeBaseX = planeBase.planeBaseX;
    item.planeBaseY = planeBase.planeBaseY;
    item.planeBaseDirect = planeBase.planeBaseDirect;
    item.planeBaseLng = planeBase.planeBaseLng;
    item.planeBaseLat = planeBase.planeBaseLat;
    this.currentLayer = item;
    this.showBeaconPlane = true;

    const result = await getBeacons({
      idxFloorId: item.pkId,
    });
    const list = result.records.map(v => {
      return {
        ...v,
        lat: Number(v.lat),
        lng: Number(v.lng),
      };
    });
    list.forEach(item => {
      const beaconMarker = new this.AMap.Marker({
        position: [item.lng, item.lat],
        draggable: true,
        zIndex: 9999999,
      });
      item.target = beaconMarker;
      this.beaconMarkerList.push(beaconMarker);
      this.map.add(beaconMarker);
    });
    this.beaconList = list;
  }
  beaconList = [];
  async handleAddBeacon() {
    const item = this.currentLayer;
    const result = await createFormModal(() => <BeaconSetting />, {
      width: 500,
      title: '信标设置',
      maskClosable: false,
    });
    console.log(result, item);
    const beaconMarker = new this.AMap.Marker({
      position: [item.planeBaseLng, item.planeBaseLat],
      draggable: true,
      zIndex: 9999999,
    });
    this.beaconList.push({
      target: beaconMarker,
      ...result,
    });
    // target._position = [113.393588, 23.062456]
    console.log(this.beaconList);
    this.beaconMarkerList.push(beaconMarker);
    this.map.add(beaconMarker);
  }
  async handleAddBeacons() {
    const list = [];
    this.beaconList.forEach(i => {
      list.push({
        pkId: i.pkId,
        beaconMac: i.beaconMac,
        beaconName: i.beaconName,
        lng: i.target._position[0].toFixed(10),
        lat: i.target._position[1].toFixed(10),
        axisX: i.axisX,
        axisY: i.axisY,
        axisZ: i.axisZ,
        alert: i.alert,
        alertContent: i.alertContent,
        signalStrength: i.signalStrength,
      });
    });
    const params = {
      idxFloorId: this.currentLayer.pkId,
      beaconDTOList: list,
    };
    await saveOrUpdate(params);
    this.showBeaconPlane = false;
  }
  menuShow = true;
  handleMenu() {
    this.menuShow = !this.menuShow;
  }
  showBeaconPlane = false;
  renderBeaconList() {
    return (
      <section class={this.$style.layerShowBox}>
        {this.beaconList.map(item => {
          return (
            <div class={this.$style.showBox}>
              <div>
                <a-icon class={this.$style.iconRight4} type="tool"></a-icon>
                <span>{item.beaconName}</span>
              </div>
              <div class={this.$style.iconBox}>
                <x-icon
                  class={this.$style.iconRight4}
                  type="tc-icon-delete"
                ></x-icon>
                <a-icon class={this.$style.iconRight4} type="edit"></a-icon>
              </div>
            </div>
          );
        })}
        <a-button icon="plus" onClick={() => this.handleAddBeacon()}>
          添加信标
        </a-button>
      </section>
    );
  }
  renderLayerShow() {
    return (
      <section class={this.$style.layerShowBox}>
        {this.formLayers.map((item, idx) => {
          return (
            <div class={this.$style.showBox}>
              <div>
                <a-icon class={this.$style.iconRight4} type="tool"></a-icon>
                <span>{item.floorName}</span>
              </div>
              <div class={this.$style.iconBox}>
                <a-icon
                  class={this.$style.iconRight4}
                  type="edit"
                  onClick={() => this.floorEdit(item)}
                ></a-icon>
                <a-icon class={this.$style.iconRight4} type="copy"></a-icon>
                <x-icon
                  class={this.$style.iconRight4}
                  type="tc-icon-delete"
                  onClick={() => this.layerRemove(item, idx)}
                ></x-icon>
                <a-icon
                  class={this.$style.iconRight4}
                  type="bulb"
                  onClick={() => this.handleBeaconSetting(item)}
                ></a-icon>
                <a-icon
                  class={this.$style.iconRight4}
                  type={item.targetShow ? 'eye' : 'eye-invisible'}
                  onClick={() => this.layerImageShow(item)}
                ></a-icon>
                <x-icon
                  class={this.$style.icon}
                  type="tc-icon-drag-handler"
                ></x-icon>
              </div>
            </div>
          );
        })}
        <div class={this.$style.showBox}>
          <div>
            <a-icon class={this.$style.iconRight4} type="gateway"></a-icon>
            <span>电子围栏名称</span>
          </div>
          <div>
            <a-icon
              class={this.$style.icon}
              type={this.newChartShow ? 'eye' : 'eye-invisible'}
              onClick={() => {
                if (this.newChartShow) {
                  this.newChart.hide();
                  this.newChartShow = false;
                } else {
                  this.newChart.show();
                  this.newChartShow = true;
                }
              }}
            ></a-icon>
          </div>
        </div>
        <div class={this.$style.showBox}>
          <div>
            <a-icon class={this.$style.iconRight4} type="radar-chart"></a-icon>
            <span>背景地图</span>
          </div>
          <div>
            <a-icon
              class={this.$style.icon}
              type={this.defaultLayerShow ? 'eye' : 'eye-invisible'}
              onClick={() => {
                if (this.defaultLayerShow) {
                  this.defaultLayer.hide();
                  this.defaultLayerShow = false;
                } else {
                  this.defaultLayer.show();
                  this.defaultLayerShow = true;
                }
              }}
            ></a-icon>
          </div>
        </div>
      </section>
    );
  }

  /** @name 楼层列表 */
  formLayers = [];
  async handleSaveLayer() {
    const imageItem = this.imageArray.find(
      v => v.ossPath === this.layerForm.floorPlanImage,
    );
    if (imageItem) {
      let options = {};
      for (let key in imageItem.img) {
        if (
          key === 'map' ||
          key === 'canvasLocal' ||
          key === 'imageLayer' ||
          key === 'canvas'
        )
          break;
        options[key] = imageItem.img[key];
      }
      if (this.layerForm.pkId) {
        // 编辑楼层
        await updateLayer({
          floorHeight: this.layerForm.floorHeight,
          floorIdentifier: this.layerForm.floorIdentifier,
          floorName: this.layerForm.floorName,
          floorPlanImage: this.layerForm.floorPlanImage,
          idxZoneFlatId: this.buildDetail.pkId,
          pkId: this.currentLayer.pkId,
          planInfo: {
            planeX: this.layerForm.planeX,
            planeY: this.layerForm.planeY,
            planeAngle: this.layerForm.planeAngle,
            planeLng: this.layerForm.planeLng,
            planeLat: this.layerForm.planeLat,
            imgOpacity: this.layerForm.imgOpacity,
            targetOptions: {
              ...options,
            },
          },
          planMarkInfo: {
            planeBaseX: this.layerForm.planeBaseX,
            planeBaseY: this.layerForm.planeBaseY,
            planeBaseDirect: this.layerForm.planeBaseDirect,
            planeBaseLng: this.layerForm.planeBaseLng,
            planeBaseLat: this.layerForm.planeBaseLat,
          },
        });
      } else {
        // 新增楼层
        const params = {
          floorHeight: this.layerForm.floorHeight,
          floorIdentifier: this.layerForm.floorIdentifier,
          floorName: this.layerForm.floorName,
          floorPlanImage: this.layerForm.floorPlanImage,
          idxZoneFlatId: this.buildDetail.pkId,
          // pkId: '',
          planInfo: {
            planeX: this.layerForm.planeX,
            planeY: this.layerForm.planeY,
            planeAngle: this.layerForm.planeAngle,
            planeLng: this.layerForm.planeLng,
            planeLat: this.layerForm.planeLat,
            imgOpacity: this.layerForm.imgOpacity,
            targetOptions: {
              ...options,
            },
          },
          planMarkInfo: {
            planeBaseX: this.layerForm.planeBaseX,
            planeBaseY: this.layerForm.planeBaseY,
            planeBaseDirect: this.layerForm.planeBaseDirect,
            planeBaseLng: this.layerForm.planeBaseLng,
            planeBaseLat: this.layerForm.planeBaseLat,
          },
        };
        await addLayer(params);
      }
      this.customLayer.hide();
      await this.fetchBuildInfo();
      this.layerFormShow = false;
    }
  }
  layerRemove(item, idx) {
    this.formLayers.splice(idx, 1);
    this.$message.success('删除成功');
  }
  async floorEdit(item) {
    const layerResult = await getLayerInfo(item.pkId);
    const plane = layerResult.planInfo;
    const image = layerResult.planInfo.targetOptions;
    const planeBase = layerResult.planMarkInfo;
    this.layerForm = {
      pkId: item.pkId,
      floorName: item.floorName,
      floorPlanImage: item.floorPlanImage,
      floorIdentifier: item.floorIdentifier,
      planeX: plane.planeX,
      planeY: plane.planeY,
      planeAngle: plane.planeAngle,
      planeLng: plane.planeLng,
      planeLat: plane.planeLat,
      planeBaseX: planeBase.planeBaseX,
      planeBaseY: planeBase.planeBaseY,
      planeBaseDirect: planeBase.planeBaseDirect,
      planeBaseLng: planeBase.planeBaseLng,
      planeBaseLat: planeBase.planeBaseLat,
      imgWidth: Number((image.width * image.scaleX).toFixed(2)),
      imgHeight: Number((image.height * image.scaleY).toFixed(2)),
      imgOpacity: plane.imgOpacity,
      floorHeight: item.floorHeight,
      target: item.target,
      targetImageLayer: item.targetImageLayer,
    };
    const abPath = await customService.getAuth(item.floorPlanImage);
    const imageItem = this.imageArray.find(
      v => v.ossPath === item.floorPlanImage,
    );
    if (imageItem) {
      let object = {};
      for (let key in imageItem.img) {
        object[key] = imageItem.img[key];
      }
      object['edited'] = true;
      this.loadImageMarker(
        abPath,
        imageItem,
        {
          abPath,
          ossPath: item.floorPlanImage,
        },
        object,
      );
      this.canvasAim.calcAnglePoint(imageItem.img, true);
      this.fileList = [imageItem];
    } else {
      const info = layerResult.planInfo?.targetOptions || {};
      this.loadImageMarker(
        abPath,
        {
          file: {
            uid: uuid(),
            name: item.floorPlanImage.slice(0, -5),
          },
        },
        {
          abPath,
          ossPath: item.floorPlanImage,
        },
        {
          ...info,
        },
      );
    }
    this.layerFormShow = true;
  }
  layerImageShow(item) {
    if (item.layerFormShow) {
      item.targetImageLayer.hide();
      item.layerFormShow = false;
    } else {
      item.targetImageLayer.show();
      item.layerFormShow = true;
    }
  }
  layerFormShow = false;
  handleUpdateLayer(item) {
    console.log(item);
  }
  handleAddLayer() {
    // 重置LayerForm表单
    this.layerForm = {
      pkId: '',
      floorName: '',
      floorPlanImage: '',
      floorIdentifier: '',
      planeX: 0,
      planeY: 0,
      planeAngle: 0,
      planeLng: 0,
      planeLat: 0,
      planeBaseX: 0,
      planeBaseY: 0,
      planeBaseDirect: 0,
      planeBaseLng: 0,
      planeBaseLat: 0,
      imgWidth: 0,
      imgHeight: 0,
      imgOpacity: 1,
      floorHeight: '',
      target: null,
      targetImageLayer: null,
    };
    this.layerFormShow = true;
    this.fileList = [];
  }
  handleCancelForm() {
    if (this.layerFormShow) {
      this.layerFormShow = false;
      this.fileList = [];
      this.customLayer.hide();
    }
    if (this.showBeaconPlane) {
      this.showBeaconPlane = false;
      this.map.remove(this.beaconMarkerList);
      this.beaconMarkerList = [];
    }
  }
  renderLayer() {
    return (
      <section
        class={[
          this.$style.listWrap,
          this.menuShow ? '' : this.$style.menuShow,
        ]}
      >
        {!this.menuShow ? (
          <span class={this.$style.mIconWrap}>
            <a-icon
              class={this.$style.mIcon}
              onClick={() => this.handleMenu()}
              type="menu-fold"
            />
          </span>
        ) : (
          <section>
            <div class={this.$style.header}>
              <span>
                <a-icon
                  class={this.$style.hIcon}
                  onClick={() => this.handleMenu()}
                  type="menu-unfold"
                />
              </span>
              {this.layerFormShow || this.showBeaconPlane ? (
                <span>
                  <a-button
                    onClick={() => {
                      this.handleCancelForm();
                    }}
                    type="primary"
                  >
                    取消
                  </a-button>
                  <a-button
                    onClick={() => {
                      if (this.layerFormShow) {
                        this.handleSaveLayer();
                      } else {
                        this.handleAddBeacons();
                      }
                    }}
                    type="primary"
                  >
                    保存
                  </a-button>
                </span>
              ) : (
                <span class={[this.$style.hTxt, this.$style.txtWrap]}>
                  <a-button onClick={() => this.handleBuildSetting()}>
                    楼栋设置
                  </a-button>
                  <a-button icon="plus" onClick={() => this.handleAddLayer()}>
                    楼层
                  </a-button>
                </span>
              )}
            </div>
            <section class={this.$style.fenceBox}>
              {this.showBeaconPlane
                ? this.renderBeaconList()
                : this.layerFormShow
                ? this.renderLayerForm()
                : this.renderLayerShow()}
            </section>
          </section>
        )}
      </section>
    );
  }
  renderLayerForm() {
    return (
      <a-form-model
        class={this.$style.fenceWrapper}
        layout="vertical"
        v-model={this.layerForm}
        rules={this.layerRules}
        ref="form"
      >
        <a-form-model-item label="楼层名称" prop="floorName">
          <a-input
            placeholder="限5字符"
            maxLength={5}
            v-model={this.layerForm.floorName}
          />
        </a-form-model-item>
        <a-form-model-item label="楼层标识符" prop="floorIdentifier">
          <a-input
            placeholder="限32字符长度，同一楼栋不允许重复"
            v-model={this.layerForm.floorIdentifier}
          />
        </a-form-model-item>
        <a-form-model-item label="楼层平面图" prop="">
          <a-upload
            list-type="picture-card"
            remove={this.handleRemove}
            customRequest={options => this.customUpload(options)}
            file-list={this.fileList}
          >
            {this.fileList.length < 1 ? (
              <div>
                <a-button>
                  <a-icon type="upload" /> 选择文件
                </a-button>
              </div>
            ) : (
              ''
            )}
          </a-upload>
          W
          <a-input-number
            class={this.$style.numWrap}
            v-model={this.layerForm.imgWidth}
          />
          H<a-input-number v-model={this.layerForm.imgHeight} />
          透明度
          <a-input-number v-model={this.layerForm.imgOpacity} />
        </a-form-model-item>
        <a-form-model-item label="平面图位置">
          X<a-input-number v-model={this.layerForm.planeX} />
          Y<a-input-number v-model={this.layerForm.planeY} />
          角度
          <a-input-number v-model={this.layerForm.planeAngle} />
        </a-form-model-item>
        <a-form-model-item label="平面图基准点">
          X<a-input-number v-model={this.layerForm.planeBaseX} />
          Y<a-input-number v-model={this.layerForm.planeBaseY} />
          方向
          <a-input-number v-model={this.layerForm.planeBaseDirect} />
        </a-form-model-item>
        <a-form-model-item label="层高" prop="floorHeight">
          <a-input placeholder="米" v-model={this.layerForm.floorHeight} />
        </a-form-model-item>
      </a-form-model>
    );
  }

  render() {
    return (
      <div class={this.$style.wrapper}>
        <div class={this.$style.mapBox} id="fn3dBox"></div>
        <div class={this.$style.optBox}>{this.renderLayer()}</div>
        <section class={this.$style.buttonWrap}>
          <a-button
            class={this.$style.btn}
            onClick={() => this.$emit('finally')}
          >
            {this.$t('msg.cancel')}
          </a-button>
          <a-button class={this.$style.btn} type="primary">
            {this.$t('msg.save')}
          </a-button>
        </section>
      </div>
    );
  }
}
</script>
<style lang="less" module>
.wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  .mapBox {
    width: 100vw;
    height: 100vh;
  }

  .optBox {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 500px;
    background-color: #fff;
  }

  .buttonWrap {
    position: absolute;
    bottom: 20px;
    right: 60px;
    text-align: right;
    padding: 20px 10px 4px 10px;

    .btn + .btn {
      margin-left: 20px;
    }
  }

  .fenceBox {
    height: 403px;
    overflow-y: auto;
  }

  .fenceWrapper {
    .numWrap {
      width: 60px;
    }
  }
}

.listWrap {
  position: absolute;
  z-index: 9999;
  padding: 6px 0;
  width: 344px;
  height: 449px;
  overflow: hidden;
  padding: 8px 10px 10px 10px;
  top: 10px;
  right: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: var(--block-bg);
  border-radius: 4px;
  transform: translateX(0);
  transition: all 0.5s ease-in-out;

  &.menuShow {
    transform: translateX(0);
    height: 32px !important;
    width: 32px;

    .zoneWrap {
      height: 0;
    }
  }

  .mIconWrap {
    width: 14px;
    height: 14px;
    display: inline-flex;

    .mIcon {
      font-size: 16px;
      color: var(--font);
      cursor: pointer;
    }
  }

  .zoneWrap {
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    .hIcon {
      font-size: 16px;
      color: var(--font);
      cursor: pointer;
    }

    .hTxt {
      font-size: 14px;
      width: auto;
      color: var(--font);
    }
  }

  .item {
    padding: 0 10px;
    height: 36px;
    font-weight: 600;
    width: 324px;
    color: var(--font);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(218, 218, 218, 0.2);
    border-radius: 4px;

    .circle {
      width: 14px;
      height: 14px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 4px;

      &.forbid {
        background-color: #ff4747;
      }

      &.active {
        background-color: #4771ff;
      }

      &.center {
        background-color: var(--font);
      }

      .sIcon {
        color: #ffffff;
        font-size: 10px;
      }
    }

    &.active {
      color: var(--font-active);
      color: #ffffff;
      background-color: var(--primary-50);

      .icon {
        color: #ffffff;
      }
    }
  }

  .item + .item {
    margin-top: 8px;
  }

  .txtWrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
  }

  .iconWrap {
    .icon {
      color: var(--font-sub);
    }

    .icon + .icon {
      margin-left: 10px;
    }
  }
}

.layerShowBox {
  .showBox {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
  }

  .iconRight4 {
    margin-right: 4px;
  }

  .icon {
    cursor: pointer;
  }
}
</style>
