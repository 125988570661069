import request from '../request';

const serviceName = '/iot-smart-helmet/geofenceZoneFlat';

/**
 * @name 获取围栏区域平面信息
 */
export function getInfo(data) {
  return request(`${serviceName}/info/${data}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    closeMessage: true,
  });
}

/**
 * @name 新增楼栋信息
 */
export function addBuilding(data) {
  return request(`${serviceName}/building`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name 编辑楼栋信息
 */
export function putBuilding(data) {
  return request(`${serviceName}/building`, {
    method: 'PUT',
    body: data,
  });
}
