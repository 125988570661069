import request from '../request';

const serviceName = '/iot-smart-helmet/geofenceFlatFloor';

/**
 * @name 获取楼层信息
 */
export function getInfo(data) {
  return request(`${serviceName}/info/${data}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

/**
 * @name 新增楼层
 */
export function addLayer(data) {
  return request(`${serviceName}/create`, {
    method: 'POST',
    body: data,
  });
}

/**
 * @name 删除楼层
 */
export function deleteLayer(data) {
  return request(`${serviceName}/delete/${data}`, {
    method: 'DELETE',
  });
}

/**
 * @name 楼层设置显隐
 */
export function displayLayer(data) {
  return request(`${serviceName}/display`, {
    method: 'PUT',
    body: data,
  });
}

/**
 * @name 编辑楼层
 */
export function updateLayer(data) {
  return request(`${serviceName}/update`, {
    method: 'PUT',
    body: data,
  });
}

/**
 * @name 楼层调整排序
 */
export function sortLayer(data) {
  return request(`${serviceName}/sort`, {
    method: 'PUT',
    body: data,
  });
}
