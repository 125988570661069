<template>
  <a-form-model
    class="fenceWrapper"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-form-model-item label="楼栋名称" prop="name">
      <a-input
        placeholder="楼栋名称，限16字符"
        :maxLength="16"
        v-model="form.name"
      />
    </a-form-model-item>
    <a-form-model-item label="楼栋ID">
      <a-input
        placeholder="用于第三方室内定位数据对接，禁止重复"
        v-model="form.buildId"
      />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class BuildingSetting extends Vue {
  @Prop({ type: Object, default: () => {} }) detail;
  form = {
    name: '',
    buildId: '',
  };
  rules = {
    name: [
      {
        required: true,
        message: '楼栋名称不能为空',
      },
    ],
  };

  mounted() {
    if (this.detail.pkId) {
      this.form.buildId = this.detail.pkId;
      this.form.name = this.detail.buildName;
    }
  }
  resolve = null;
  reject = null;
  async getValue() {
    try {
      await this.$refs.form.validate();
      return this.form;
    } catch (error) {
      return false;
    }
  }

  formatTrim(value = '') {
    return value.trim();
  }
}
</script>

<style lang="less" module>
.tipText {
  color: #999999;
  font-size: 13px;
  margin-left: 4px;
}
</style>
