// @ts-nocheck

import { FabricObject } from '../FabricObject';
import { Util } from '../Util';

export class FabricImage extends FabricObject {
  type = 'image';
  _element;
  /** 默认通过 img 标签来绘制，因为最终都是要通过该标签绘制的 */
  constructor(element, options) {
    super(options);
    this._initElement(element, options);
  }
  _initElement(element, options) {
    this.setElement(element, options);
  }
  setElement(element, options) {
    this._element = element;
    this._initConfig(options);
    return this;
  }
  _initConfig(options = {}) {
    this.setOptions(options);
    this._setWidthHeight(options);
  }
  /** 设置图像大小 */
  _setWidthHeight(options) {
    this.width =
      'width' in options
        ? options.width
        : this.getElement()
        ? this.getElement().width || 0
        : 0;
    this.height =
      'height' in options
        ? options.height
        : this.getElement()
        ? this.getElement().height || 0
        : 0;
  }
  getElement() {
    return this._element;
  }
  /** 直接调用 drawImage 绘制图像 */
  _render(ctx, noTransform = false) {
    let x, y, elementToDraw;

    x = noTransform ? this.left : -this.width / 2;
    y = noTransform ? this.top : -this.height / 2;
    let scaledWidth = this.width;
    let scaledHeight = this.height;
    if (!this.active && this.canvasLocal.zoomChange) {
      const v = this.canvasLocal.defaultZoom / this.canvasLocal.zoom;
      scaledWidth = this.width * v;
      scaledHeight = this.height * v;
    }
    elementToDraw = this._element;
    elementToDraw &&
      ctx.drawImage(elementToDraw, x, y, scaledWidth, scaledHeight);
  }
  /** 如果是根据 url 或者本地路径加载图像，本质都是取加载图片完成之后在转成 img 标签 */
  static fromURL(url, callback, imgOptions) {
    // debugger;
    Util.loadImage(url).then(img => {
      callback && callback(new FabricImage(img, imgOptions));
    });
  }
  static async = true;
}
