import request from '../request';

const serviceName = '/iot-smart-helmet/geofenceFloorBeacon';

/**
 * @name 分页获取楼层信标信息
 */
export function page(data) {
  return request(`${serviceName}/page`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @name 新增编辑信标
 */
export function saveOrUpdate(data) {
  return request(`${serviceName}/saveOrUpdate`, {
    method: 'POST',
    body: data,
  });
}
