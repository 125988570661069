<template>
  <a-form-model
    class="fenceWrapper"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-form-model-item label="信标名称" prop="beaconName">
      <a-input
        placeholder="信标名称，限16字符"
        :maxLength="16"
        v-model="form.beaconName"
      />
    </a-form-model-item>
    <a-form-model-item label="信标mac/唯一标识">
      <a-input placeholder="信标唯一标识，禁止重复" v-model="form.beaconMac" />
    </a-form-model-item>
    <a-form-model-item label="部署位置">
      <p style="font-size:12px">
        说明：保存后点击地图选点可自动生成以下数据，支持地图拖拽/修改数值编辑
      </p>
      <section><span>经度</span><a-input v-model="form.lng" /></section>
      <section><span>纬度</span><a-input v-model="form.lat" /></section>
      <section>
        <span>X</span><a-input-number v-model="form.axisX" /><span>米</span>
        <span>Y</span><a-input-number v-model="form.axisY" /><span>米</span>
        <span>Z</span><a-input-number v-model="form.axisZ" /><span>米</span>
      </section>
    </a-form-model-item>
    <div>警报设置</div>
    <a-form-model-item label="启动警报">
      <a-switch
        default-checked
        v-model="form.alert"
        style="margin-right: 10px;"
      />
    </a-form-model-item>
    <a-form-model-item label="信号强度" v-if="form.alert">
      <a-select v-model="form.signalStrength">
        <a-select-option value="STRONG">
          强信号（-30到-60dBm）
        </a-select-option>
        <a-select-option value="MIDDLE">
          中等信号（-61到-80dBm）
        </a-select-option>
        <a-select-option value="WEAK">
          弱信号（-81到-100dBm）
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="警报内容" v-if="form.alert">
      <a-input
        :maxLength="32"
        placeholder="靠近当前信标的报警内容，限32字符长度"
        v-model="form.alertContent"
      />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class BeaconSetting extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  isNew = true;
  form = {
    beaconName: '',
    beaconMac: '',
    activateAlarm: false,
    lng: '',
    lat: '',
    axisX: '',
    axisY: '',
    axisZ: '',
    alert: false,
    alertContent: '',
    signalStrength: null,
  };
  rules = {
    beaconName: [
      {
        required: true,
        message: '名称不能为空',
      },
    ],
  };

  mounted() {
    // this.isNew = !(this.editData && Object.keys(this.editData).length > 0);
    // if (!this.isNew) {
    //   this.form.projectId = this.editData.projectId;
    //   this.form.geofenceId = this.editData.geofenceId;
    //   this.form.geofenceName = this.editData.geofenceName;
    //   this.form.isDefault = this.editData.isDefault;
    //   const permission = this.editData.permission;
    //   this.form.groupType = permission.project ? 3 : permission.equip ? 1 : 2;
    //   this.form.equip = permission.equip || [];
    //   this.form.group = permission.group || [];
    //   this.form.project =
    //     (permission.project && permission.project[0]) || undefined;
    // }
  }
  resolve = null;
  reject = null;
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            resolve(this.form);
          } catch (error) {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }

  formatTrim(value = '') {
    return value.trim();
  }
}
</script>

<style lang="less" module>
.tipText {
  color: #999999;
  font-size: 13px;
  margin-left: 4px;
}
</style>
