export class Point {
  x;
  y;
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
  /** 返回一个新的点，值为两个点的最小x、y值 */
  min(otherPoint) {
    return new Point(
      Math.min(this.x, otherPoint.x),
      Math.min(this.y, otherPoint.y),
    );
  }
  /** 返回一个新的点，值为两个点的最大x、y值 */
  max(otherPoint) {
    return new Point(
      Math.max(this.x, otherPoint.x),
      Math.max(this.y, otherPoint.y),
    );
  }

  /** += 的意思，会改变自身的值 */
  addEquals(point) {
    this.x += point.x;
    this.y += point.y;
    return this;
  }
  /** -= 的意思，会改变自身的值 */
  subtractEquals(point) {
    this.x -= point.x;
    this.y -= point.y;
    return this;
  }
}
